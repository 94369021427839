import React from 'react';
import { Link } from 'gatsby';
import icon1 from '../../assets/images/services/Management-Dashboard.svg';
import icon2 from '../../assets/images/services/EMM-Support.svg';
import icon3 from '../../assets/images/services/Conditional-Access.svg';
import icon4 from '../../assets/images/services/Remote-Device-Management.svg';
import { isMobile } from 'react-device-detect';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const RelatedServices = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };

    return (
        <div>
            {!isMobile && (
                <div className="container services-area pt-100 pb-100">
                    <div className="section-title">
                        <h2>
                            <span>And so</span> Much More!
                        </h2>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-6 col-sm-12 single-services-box-border">
                            <div className="single-services-box">
                                <Link to="/corporate/management-dashboard/">
                                    <div className="icon">
                                        <img src={icon1} alt="about" />
                                    </div>
                                    <h3>
                                        <span>Management</span>
                                        <br /> Dashboard
                                    </h3>
                                    <p>
                                        A one-stop web application for your corporate IT. Easy
                                        management of user profiles, device monitoring, and
                                        analytics. We work in conjunction with your Enterprise
                                        Mobility Management (EMM) service and we also offer a
                                        standalone solution.
                                    </p>
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12 single-services-box-border">
                            <div className="single-services-box">
                                <div className="icon">
                                    <img src={icon2} alt="about" />
                                </div>
                                <h3>
                                    <span>EMM</span>
                                    <br /> Support
                                </h3>
                                <p>
                                    Support for EMM solutions, including MobileIron, AirWatch,
                                    Intune, and MaaS360. Customize your server configurations and
                                    authentication methods. Our Enterprise Mobility Management
                                    (EMM) solution is the perfect match for securing your
                                    corporate accounts.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12 single-services-box-border">
                            <div className="single-services-box">
                                <Link to="/corporate/conditional-access/">
                                    <div className="icon">
                                        <img src={icon3} alt="about" />
                                    </div>
                                    <h3>
                                        <span>Conditional</span>
                                        <br /> Access
                                    </h3>
                                    <p>
                                        Configure policies for when, where, and by whom your corporate
                                        data can be accessed. Corporate information should only be
                                        accessible by those who need to know, and we make sure that
                                        you are in full control.
                                    </p>
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12 single-services-box-border">
                            <div className="single-services-box">
                                <Link to="/corporate/remote-lock-screen-management/">
                                    <div className="icon">
                                        <img src={icon4} alt="about" />
                                    </div>
                                    <h3>
                                        <span>Remote Device</span>
                                        <br /> Management
                                    </h3>
                                    <p>
                                        Enforce lock screen password policies and expirations, disable
                                        device screenshots, secure vital emails and communications,
                                        and activate a remote wipe of all company information. You can
                                        be sure that all of your corporate data is within reach.
                                    </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {display && isMobile ? (
                <div className="services-area-testimonials-mobile">
                    <div className="section-title">
                        <h2>
                            <span>And so</span> Much More!
                        </h2>
                    </div>
                    <Slider
                        className="testimonials-slides owl-carousel owl-theme"
                        {...settings}
                    >
                        <div className="single-services-box">
                            <Link to="/corporate/management-dashboard/">
                                <div className="icon">
                                    <img src={icon1} alt="about" />
                                </div>
                                <h3>
                                    <span>Management</span>
                                    <br /> Dashboard
                                </h3>
                                <p>
                                    A one-stop web application for your corporate IT. Easy
                                    management of user profiles, device monitoring, and analytics.
                                    We work in conjunction with your Enterprise Mobility Management
                                    (EMM) service and we also offer a standalone solution.
                                </p>
                            </Link>
                        </div>

                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <span>EMM</span>
                                <br /> Support
                            </h3>
                            <p>
                                Support for EMM solutions, including MobileIron, AirWatch, Intune,
                                and MaaS360. Customize your server configurations and
                                authentication methods. Our Enterprise Mobility Management (EMM)
                                solution is the perfect match for securing your corporate accounts.
                            </p>
                        </div>

                        <div className="single-services-box">
                            <Link to="/corporate/conditional-access/">
                                <div className="icon">
                                    <img src={icon3} alt="about" />
                                </div>
                                <h3>
                                    <span>Conditional</span>
                                    <br /> Access
                                </h3>
                                <p>
                                    Configure policies for when, where, and by whom your corporate
                                    data can be accessed. Corporate information should only be
                                    accessible by those who need to know, and we make sure that you
                                    are in full control.
                                </p>
                            </Link>
                        </div>

                        <div className="single-services-box">
                            <Link to="/corporate/conditional-access/">
                                <div className="icon">
                                    <img src={icon3} alt="about" />
                                </div>
                                <h3>
                                    <span>Conditional</span>
                                    <br /> Access
                                </h3>
                                <p>
                                    Configure policies for when, where, and by whom your corporate
                                    data can be accessed. Corporate information should only be
                                    accessible by those who need to know, and we make sure that you
                                    are in full control.
                                </p>
                            </Link>
                        </div>
                    </Slider>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default RelatedServices;
