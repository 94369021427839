import React from 'react'
import ReactWOW from 'react-wow'
import bannerImg from '../../assets/images/corporate/ssal-1.webp'

const Banner = () => {
    return (
        <div className="features-services-banner">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="main-banner-content">
                            <div className="main-banner-content-wrapper">
                                <h1>BLUEMAIL <span>WORK</span></h1>
                                <div className="main-banner-divider" />
                                <p>Whether you’re a small team working from home, or a global corporation with thousands of employees, BlueMail Work delivers everything that you need to ensure high-quality productivity and management of your corporate accounts</p>
                                <div className="option-item">
                                    <a 
                                        href="mailto:sales@bluemail.me" 
                                        activeClassName="active"
                                        className="default-btn"
                                    >
                                        Contact us <span></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="main-banner-image">
                                <img src={bannerImg} alt="banner" />
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
            <div className="features-services-banner-end" />
        </div>
    )
}

export default Banner
