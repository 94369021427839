import React from 'react'
import SEO from '../components/App/SEO'
import Layout from '../components/App/Layout'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Banner from "../components/Corporate/Banner"
import RegularBanner from '../components/Corporate/RegularBanner'
import RelatedServices from '../components/Corporate/RelatedServices'
import ReversedBanner from '../components/Corporate/ReversedBanner'
import devicesImg from '../assets/images/corporate/devices.webp'
import configurationsImg from '../assets/images/corporate/configurations.webp'
import contactsImg from '../assets/images/corporate/corp-contacts.webp'
import supportImg from '../assets/images/corporate/sup-1.webp'
import { graphql } from 'gatsby'

const deviceIntegrate = {
    title: <h1><span>Bring Your</span> Own Device</h1>,
    subtitle: "No need for a corporate only device since you can integrate BlueMail Work securely within personal devices. Having the ability to enable corporate accounts on both corporate and employee-owned devices is a win-win for every company.",
    image: devicesImg,
    leftBG: '',
    link: '',
    imageClass: ''
}

const configurations = {
    title: <h1><span>Custom</span> Configurations</h1>,
    subtitle: "Enable specific corporate configurations and settings for all employees such as Days to Sync, AMOLED theme, VIP Notifications, and Send Later. It’s easier than ever to manage your company interests across the board.",
    image: configurationsImg,
    link: ''
}

const contacts = {
    title: <h1><span>Corporate</span> Contacts</h1>,
    subtitle: "Corporate-wide contact synchronization, support for Global Address List (GAL) and LDAP / Active Directory. Managing and keeping track of co-workers and clients is simple and straightforward.",
    image: contactsImg,
    link: '/corporate/corporate-contacts/'
}

const support = {
    title: <h1><span>Priority</span> Support</h1>,
    subtitle: "Our team is ready to provide you with email assistance within 24 hours, along with a high-quality SLA (Service-Level Agreement). Your inquiries will receive our attention first and foremost.",
    image: supportImg,
    leftBG: 'banner-left-pane-bg',
    link: '/corporate/priority-support/',
    imageClass: 'main-product-img'
}

const Corporate = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
            <Navbar />
            <SEO 
                postTitle='Work | BlueMail App' 
                postDescription='BlueMail Work provides Enterprise solutions for your team.'
                postImage="/img/OG/og_image_Work.png"
                postURL='corporate'
                postSEO
            />
            <Banner />
            <RegularBanner data={deviceIntegrate} />
            <RelatedServices />
            <ReversedBanner data={configurations} />
            <ReversedBanner data={contacts} />
            <RegularBanner data={support} />
            <Footer />
        </Layout>
    );
}

export default Corporate;

export const query = graphql`
query CorporatePageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`